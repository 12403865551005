import { fetchEndpoint } from "@/server/network1";
import axios from "axios";

//获取信息通知分页数据（本校）
export const getPageList = (data) =>
  fetchEndpoint("/school/information/getPageList", { ...data }, true, "POST");
//获取信息详情
export const getEntityById = (data) =>
  fetchEndpoint("/school/information/getEntityById", { ...data }, true, "POST");
//提交反馈
export const updateBackByMsgCode = (data) =>
  fetchEndpoint(
    "/school/information/updateBackByMsgCode",
    { ...data },
    true,
    "POST"
  );
//查看反馈详情
export const getBackByMsgCode = (data) =>
  fetchEndpoint(
    "/school/information/getBackByMsgCode",
    { ...data },
    true,
    "POST"
  );

//获取信息详情
export const getMsgInfoById = (data) =>
  fetchEndpoint(
    "/school/information/getMsgInfoById",
    { ...data },
    true,
    "POST"
  );
//撤回反馈信息
export const revokeByMsgCode = (data) =>
  fetchEndpoint(
    `/school/information/revokeByMsgCode/${data}`,
    { ...data },
    true,
    "POST"
  );
