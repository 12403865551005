import { render, staticRenderFns } from "./newsNotice.vue?vue&type=template&id=6e9e6794&scoped=true"
import script from "./newsNotice.vue?vue&type=script&lang=js"
export * from "./newsNotice.vue?vue&type=script&lang=js"
import style0 from "./newsNotice.vue?vue&type=style&index=0&id=6e9e6794&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9e6794",
  null
  
)

export default component.exports