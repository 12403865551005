<template>
  <div class="deviceBill">
    <div class="pageHeader">信息通知</div>
    <div class="pageSearch">
      <a-form-model :model="searchForm" layout="inline">
        <a-form-model-item label="发布时间:">
          <a-date-picker
            v-model="dateTime"
            class="searchIpt"
            @change="changeTime"
          />
        </a-form-model-item>
        <a-form-model-item label="发布部门：">
          <a-input
            v-model="searchForm.apartment"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item label="信息标题：">
          <a-input
            v-model="searchForm.title"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item label="创建人：">
          <a-input
            v-model="searchForm.creater"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" class="searchBtn" @click="search"
            >查询</a-button
          >
          <a-button class="searchBtn" @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="pageContent">
      <div class="pageContentHead">
        <div class="pageContent_title">信息记录</div>
      </div>
      <div class="pageContent_table">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="tableLoading"
          size="middle"
          :pagination="pagination"
        >
          <div slot="type" slot-scope="text, data, index">
            {{ data.msgType == 1 ? "通知" : "公告" }}
          </div>
          <div slot="actions" slot-scope="text, data, index">
            <a-button
              type="link"
              @click="
                toInfo(
                  data.id,
                  data.receiveStatus,
                  data.msgStatus,
                  data.receiveCode
                )
              "
              >详情</a-button
            >
            <a-button
              type="link"
              @click="toFeedbackInfo(data.receiveCode)"
              v-if="data.receiveStatus == 2"
              >看反馈</a-button
            >
            <a-button
              type="link"
              @click="toFeedback(data.receiveCode, data.receiveStatus)"
              v-if="data.receiveStatus !== 2 && data.msgStatus !== 3"
              >反馈</a-button
            >
            <a-button
              type="link"
              @click="toCancel(data.receiveCode)"
              v-if="data.receiveStatus == 2 && data.msgStatus !== 3"
              >撤回</a-button
            >
          </div>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageList, revokeByMsgCode } from "@/api/newsManage";
export default {
  name: "newsNotice",
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      searchForm: {
        date: "",
        apartment: "",
        title: "",
        creater: "",
      },
      dateTime: undefined,
      columns: [
        {
          title: "信息标题",
          dataIndex: "msgTitle",
          align: "center",
        },
        {
          title: "信息类型",
          align: "center",
          scopedSlots: {
            customRender: "type",
          },
        },
        {
          title: "发布部门",
          dataIndex: "msgDept",
          align: "center",
        },
        {
          title: "创建人",
          dataIndex: "msgPublisherName",
          align: "center",
        },
        {
          title: "发布时间",
          dataIndex: "createTimeStr",
          align: "center",
        },
        {
          title: "截止时间",
          dataIndex: "deadline",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {
            customRender: "actions",
          },
        },
      ],
      dataSource: [],
    };
  },
  components: {},
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.searchForm = {
        date: "",
        apartment: "",
        title: "",
        creater: "",
      };
      this.dateTime = undefined;
    },
    toInfo(id, status, msgStatus, code) {
      this.$router.push(
        "/newsInfo?id=" +
          id +
          "&status=" +
          status +
          "&code=" +
          code +
          "&msgStatus=" +
          msgStatus
      );
    },
    toFeedback(code, status) {
      this.$router.push("/newsFeedback?code=" + code + "&status=" + status);
    },
    toFeedbackInfo(code, title) {
      this.$router.push("/feedbackInfo?code=" + code);
    },
    toCancel(code) {
      revokeByMsgCode(code).then((res) => {
        console.log(res, res);
        if (res.data.code == 200) {
          this.getList();
          this.$message.success("操作成功");
        }
      });
    },
    changePage(page) {
      this.pagination.current = page;
      this.getList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getList();
    },
    changeTime(date, dateString) {
      this.searchForm.date = dateString;
    },
    search() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      let data = {
        createDay: this.searchForm.date,
        dept: this.searchForm.apartment,
        title: this.searchForm.title,
        name: this.searchForm.creater,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      getPageList(data).then((res) => {
        this.dataSource = res.data.data.records;
        this.pagination.total = parseInt(res.data.data.total);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchIpt {
  width: 200px;
}
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
}
.pageSearch {
  margin: 20px 0;
  background: white;
  padding: 20px;
}
.searchBtn {
  margin-right: 10px;
}
.searchSelect {
  width: 200px;
}
.pageContent_title {
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 27px;
  position: relative;
  color: rgba(16, 16, 16, 100);
  font-weight: bold;
}
.pageContent_title::before {
  width: 4px;
  height: 20px;
  left: -7px;
  top: 0;
  position: absolute;
  content: "";
  background-color: #555555;
}
.pageContent_table {
  margin-top: 20px;
  background-color: white;
}
.pageContentHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
